<template>
    <div class="page1">
        <EForm :formColumns="formColumns"
               :rowSize="4"
               :optionsBtn="true"
               :actionBtn="false"
               :formData="searchForm"
               ref="form"
               @onSearch="getData"
               :searchFlag="true"
               :exportShow="false"
               >
        </EForm>
        <EButton type="primary" @click="dialogShow" size="small">增加</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="editDialog(scope.row.placeId,scope.row.placeChargeRateConfigId)">
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row.placeChargeRateConfigId)">
                            删除
                        </EButton>
                        <EButton type="text" @click="viewDialog(scope.row.placeId,scope.row.placeChargeRateConfigId)">
                            查看
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>

        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
                 width="60%"
                 @handleClick="saveData"
        :disabled="saveDisabled"
        @open="changeInfo(1)">
            <el-form ref="form" :model="dialogForm" label-position="right" label-width="150px"
                     :rules="formRules">
                <el-form-item label='位置名称' prop="placeId">
                    <el-select v-model="dialogForm.placeId" placeholder="请选择" @change="getAreaId" :disabled="!!dialogForm.configId">
                        <el-option
                                v-for="item in formColumns[0].options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>

                </el-form-item>
                <el-form-item label='按面积计算费用标准'>
                    <el-table
                            :data="placeChargesData"
                            style="width: 100%"
                            :header-cell-style="{background:'#f4f4f5'}" border size="small"
                    >
                        <el-table-column
                                prop="itemName"
                                label="收费项目"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="unitPrice"
                                label="单价"
                                width="250px"
                        >
                            <template slot-scope="scope">
                                <el-radio-group v-model="scope.row.radio" @change="changeRadio('placeChargesData',scope.$index,scope.row.radio)" >
                                    <el-radio
                                            :label="1" class="mb10" :value="1">
                                        <el-input placeholder="单价"
                                                  v-model="scope.row.unitPrice"
                                                  style="width: 190px"></el-input></el-radio>
                                    <el-radio :label="2" :value="2">
                                        <el-select v-model="scope.row.standardType"
                                                   placeholder="请选择"
                                                   style="width: 190px">
                                            <el-option
                                                    label="公共水费标准"
                                                    value="1">
                                            </el-option>
                                        </el-select>
                                    </el-radio>
                                </el-radio-group>

                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="chargeWay"
                                label="周期"

                        >
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.chargeWay" placeholder="请选择">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>

                </el-form-item>
                <el-form-item label='固定费用标准'>
                    <el-table
                            :data="fixedChargesData"
                            style="width: 100%"
                            :header-cell-style="{background:'#f4f4f5'}" border size="small">
                        <el-table-column
                                prop="itemName"
                                label="收费项目"
                        >
                        </el-table-column>

                        <el-table-column
                                prop="unitPrice"
                                label="单价"
                                width="250px"
                        >
                            <template slot-scope="scope">
                                <el-radio-group v-model="scope.row.radio"  @change="changeRadio('fixedChargesData',scope.$index,scope.row.radio)">
                                    <el-radio :label="1" class="mb10" :value="1">
                                        <el-input placeholder="单价"
                                                  v-model="scope.row.unitPrice"
                                                  style="width: 190px"></el-input></el-radio>
                                    <el-radio :label="2" :value="2">
                                        <el-select v-model="scope.row.standardType"
                                                   placeholder="请选择"
                                                   style="width: 190px">
                                            <el-option
                                                    label="公共水费标准"
                                                    value="1">
                                            </el-option>
                                        </el-select>
                                    </el-radio>
                                </el-radio-group>

                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="chargeWay"
                                label="周期"
                        >
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.chargeWay" placeholder="请选择">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>

                </el-form-item>
            </el-form>
        </EDialog>
        <EDialog :dialogVisible="dialogVisible1" :title="title" @handleClose="handleClose" @handleClick="handleClose"
                 width="60%"
        :disabled="false">
            <el-form ref="form" :model="dialogForm" label-position="right" label-width="150px"
                     :rules="formRules">
                <el-form-item label='位置名称' prop="placeId">
                    <el-select v-model="dialogForm.placeId" placeholder="请选择" @change="getAreaId" :disabled="true">
                        <el-option
                                v-for="item in formColumns[0].options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>

                </el-form-item>
                <el-form-item label='按面积计算费用标准'>
                    <el-table
                            :data="placeChargesData"
                            style="width: 100%"
                            :header-cell-style="{background:'#f4f4f5'}" border size="small"
                    >
                        <el-table-column
                                prop="itemName"
                                label="收费项目"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="unitPrice"
                                label="单价"
                                width="250px"
                        >
                            <template slot-scope="scope">
                                <el-radio-group v-model="scope.row.radio" @change="changeRadio('placeChargesData',scope.$index,scope.row.radio)" >
                                    <el-radio
                                            :label="1" class="mb10" :value="1" :disabled="true">
                                        <el-input placeholder="单价"
                                                  v-model="scope.row.unitPrice"
                                                  style="width: 190px" :disabled="true"></el-input></el-radio>
                                    <el-radio :label="2" :value="2" :disabled="true">
                                        <el-select v-model="scope.row.standardType"
                                                   placeholder="请选择"
                                                   style="width: 190px" :disabled="true">
                                            <el-option
                                                    label="公共水费标准"
                                                    value="1">
                                            </el-option>
                                        </el-select>
                                    </el-radio>
                                </el-radio-group>

                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="chargeWay"
                                label="周期"

                        >
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.chargeWay" placeholder="请选择" :disabled="true">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>

                </el-form-item>
                <el-form-item label='固定费用标准'>
                    <el-table
                            :data="fixedChargesData"
                            style="width: 100%"
                            :header-cell-style="{background:'#f4f4f5'}" border size="small">
                        <el-table-column
                                prop="itemName"
                                label="收费项目"
                        >
                        </el-table-column>

                        <el-table-column
                                prop="unitPrice"
                                label="单价"
                                width="250px"
                        >
                            <template slot-scope="scope">
                                <el-radio-group v-model="scope.row.radio"  @change="changeRadio('fixedChargesData',scope.$index,scope.row.radio)">
                                    <el-radio :label="1" class="mb10" :value="1" :disabled="true">
                                        <el-input placeholder="单价"
                                                  v-model="scope.row.unitPrice"
                                                  style="width: 190px" :disabled="true"></el-input></el-radio>
                                    <el-radio :label="2" :value="2">
                                        <el-select v-model="scope.row.standardType"
                                                   placeholder="请选择"
                                                   style="width: 190px" :disabled="true">
                                            <el-option
                                                    label="公共水费标准"
                                                    value="1">
                                            </el-option>
                                        </el-select>
                                    </el-radio>
                                </el-radio-group>

                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="chargeWay"
                                label="周期"
                        >
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.chargeWay" placeholder="请选择" :disabled="true">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>

                </el-form-item>
            </el-form>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import ETree from '@/components/ETree'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  export default {
    name: 'Login',
    mixins:[otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName'
          },
          // {
          //   label: '摊位号',
          //   prop: 'boothCode'
          // },
          // {
          //   label: '收费项',
          //   prop: 'takeReturnProjectName'
          // },
          // {
          //   label: '单价',
          //   prop: 'unitPrice'
          // },
          // {
          //   label: '计费方式',
          //   prop: 'chargeMode'
          // },
          // {
          //   label: '收费方式',
          //   prop: 'chargeWay'
          // },
          {
            label: '新建时间',
            prop: 'createTime',
          },
          {
            label: '新建人',
            prop: 'createUserCn'
          },
          {
            label: '修改时间',
            prop: 'updateTime'
          },
          {
            label: '修改人',
            prop: 'updateUserCn'
          },
        ],
        tableData: [],
        formColumns: [
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          // {
          //   title: '摊位号',
          //   type: 'text',
          //   property: 'areaId',
          //   show: true,
          // },
        ],
        searchForm: {
          placeId: '',
          current: 1,
          size: 10
        },
        count: null,
        placeChargesData: [],
        fixedChargesData: [],
        dialogVisible: false,
        dialogVisible1: false,
        formRules: {
          placeId: vxRule(true, '', "change", "位置不能为空")
        },
        dialogForm: {
          placeId: '',
          configId: ''
        },
        options: [
          {
            label: '一次',
            value: '1',
          },
          {
            label: '月',
            value: '2',
          },
          // {
          //   label: '月',
          //   value: '3',
          // },
          // {
          //   label: '日',
          //   value: '4',
          // }
        ],
        title: '新增区域收退费标准',
        status:null
      }
    },
    watch: {},
    created() {
      this.getData()
      this.getPlaceLabel()
    },
    components: {ETable, EDialog, EButton, EForm, ETree},
    beforeMount() {

    },
    methods: {
      changeRadio(arr,index,value){
        if(value==2){
          //当他等于2的时候，就是选中了公共标准水价格
          this[arr][index].unitPrice=''
          this[arr][index].standardType='1'
        }else{
          this[arr][index].unitPrice=''
          this[arr][index].standardType=''
        }
      },
      changeInfo(status){
        this.status=status
        this.getPlaceLabel()
      },
      changePage(current) {
        this.searchForm.current = current
        this.getData()
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      async getAreaId() {
        let res = await Http.cmareachargerateconfigItems({
          placeId: this.dialogForm.placeId,
          configId: this.dialogForm.configId
        })
        if (res.code == 200) {
          this.placeChargesData = res.data.placeCharges
          this.fixedChargesData = res.data.fixedCharges
          if(!this.dialogForm.configId){
            this.placeChargesData.forEach((item)=>{
              item.radio=1
            })
            this.fixedChargesData.forEach((item)=>{
              item.radio=1
            })
          }else{
            this.placeChargesData.forEach((item)=>{
              if(item.standardType==1){
                item.radio=2
              }else{
                item.radio=1
              }
            })
            this.fixedChargesData.forEach((item)=>{
              if(item.standardType==1){
                item.radio=2
              }else{
                item.radio=1
              }
            })
          }
        }else{
          this.placeChargesData=[]
          this.fixedChargesData=[]
        }
      },
      async cmareachargerateconfigRemove(ids) {
        let res = await Http.cmareachargerateconfigRemove({ids})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      remove(id) {
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.cmareachargerateconfigRemove(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editDialog(placeId, placeChargeRateConfigId) {
        this.dialogForm.placeId = placeId
        this.dialogForm.configId = placeChargeRateConfigId
        this.getAreaId()
        this.dialogVisible = true
        this.title = '修改区域收退费标准'
      },
      viewDialog(placeId, placeChargeRateConfigId) {
        this.dialogForm.placeId = placeId
        this.dialogForm.configId = placeChargeRateConfigId
        this.getAreaId()
        this.dialogVisible1 = true
        this.title = '查看区域收退费标准'
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          status:this.status
        })
        if (res.code == 200) {
          this.formColumns[0].options = res.data
        }
      },
      // //获取区域下拉
      // async getAreaLabel() {
      //   let res = await Http.getAreaLabel()
      //   if (res.code == 200) {
      //     this.formColumns[0].options = res.data
      //
      //   }
      // },
      resetFrom() {
        this.$refs.form.resetFields()
      },
      dialogShow() {
        this.dialogVisible = true
      },
      cancelDialog() {
        this.dialogVisible = false
        this.title = '新增区域收退费标准'
        this.dialogForm = {
          placeId: '',
          configId: ''
        }
        this.placeChargesData = []
        this.fixedChargesData = []
        this.changeInfo(null)
        this.$refs.form.resetFields();
      },
      showDialog() {
        this.dialogVisible = true
      },
      handleClose() {
        this.dialogVisible1 = false
      },
      async saveData() {
        this.$refs['form'].validate((valid, object) => {
          if (valid && !this.error) {
            this.cmareachargerateconfigSave()
          } else {
            return false;
          }
        });

      },
      async cmareachargerateconfigSave() {
        this.setDisabled(true)
        let res = await Http.cmareachargerateconfigSave({
          placeChargeRateConfigId: this.dialogForm.configId,
          placeId: this.dialogForm.placeId,
          placeCharges: this.placeChargesData,
          fixedCharges: this.fixedChargesData
        })
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      async getData() {
        let res = await Http.cmareachargerateconfigPage(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data.records
          this.count = Number(res.data.total)
        }
      }
    }
  }
</script>

<style lang="scss">

</style>
